<template>
    <v-navigation-drawer
        v-model="active"
        right
        fixed
        temporary
        width="600"
    >
        <v-container class="pa-10" v-if="order">
            <v-row>
                <v-col>
                    <v-row class="align-center">
                        <v-btn icon class="black--text" @click="onBackSelected">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <span class="headline" style="white-space: nowrap">Pedido #{{ id }}</span>
                    </v-row>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <span class="subtitle-1 font-weight-medium"><v-icon class="mr-2">mdi-text-subject</v-icon>Resumen</span>

                    <v-list dense class="t-info-list">
                        <v-list-item>
                            <v-list-item-content>Cliente</v-list-item-content>
                            <v-list-item-content class="align-end">{{ customer.fullName | capitalize }}</v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="order.date">
                            <v-list-item-content>Fecha</v-list-item-content>
                            <v-list-item-content class="align-end">{{ order.date | moment('dddd D MM/Y') | capitalize }}</v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>Total</v-list-item-content>
                            <v-list-item-content class="align-end">{{ order.total | toCurrency }}</v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <span class="subtitle-1 font-weight-medium"><v-icon class="mr-2">mdi-truck-outline</v-icon>Entrega</span>

                    <v-list dense class="t-info-list">
                        <v-list-item>
                            <v-list-item-content>Fecha de Entrega</v-list-item-content>
                            <v-list-item-content v-if="order && order.deliveryDate">
                                {{ order.deliveryDate | moment('dddd D MM/Y') | capitalize }}
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="order.deliverySchedule">
                            <v-list-item-content>Horario de Entrega</v-list-item-content>
                            <v-list-item-content>
                                {{ order.deliverySchedule.start }} - {{ order.deliverySchedule.end }}
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <span class="subtitle-1 font-weight-medium"><v-icon class="mr-2">mdi-comment-outline</v-icon>Comentarios</span>
                </v-col>

                <v-col cols="12">
                    <order-comments :order="order"></order-comments>
                </v-col>
            </v-row>
        </v-container>

        <template v-slot:append>
            <v-row class="mt-4 px-4 py-2 drawer-bottom">
                <v-text-field
                    class="comment-field"
                    outline
                    v-model="fieldComment"
                    label="Escribir comentario..."
                    auto-grow
                    solo hide-details
                    @keydown.enter="onSaveCommentSelected"
                    :loading="isSaving"
                ></v-text-field>
            </v-row>
        </template>
    </v-navigation-drawer>
</template>

<script>
    import OrderComments from '@/components/Order/OrderComments';

    export default {
        components: {
            OrderComments
        },

        props: {
            value: Boolean,
            order: Object
        },

        data() {
            return {
                active: false,
                isLoading: false,
                isSaving: false,
                fieldComment: ''
            };
        },

        computed: {
            id() {
                if(this.order) {
                    return this.order.id;
                }

                return null;
            },

            customer() {
                if(this.order) {
                    return this.$store.getters['customer/getById'](this.order.customer);
                }

                return {};
            },

            items() {
                if(this.order) {
                    return this.order.items;
                }

                return null;
            },

            state() {
                if(this.order) {
                    return this.order.state;
                }

                return null;
            }

        },

        watch: {
            active(val) {
                this.$emit('input', val);
            },

            value(val) {
                this.active = val;
            },

            order(val, prev) {
                if(!val || (prev && val.id === prev.id)) {
                    return;
                }

                this.isLoading = true;

                this.$store.dispatch('order/fetchOne', { id: val.id }).then((result) => {
                    return this.$store.dispatch('order/fetchComments', { order: val });
                }).finally((result) => {
                    this.isLoading = false;
                });
            }
        },

        methods: {
            onBackSelected() {
                this.$emit('input', false);
            },

            async onSaveCommentSelected() {
                this.isSaving = true;

                try {
                    await this.$store.dispatch('order/addComment', { order: this.order, comment: this.fieldComment });
                }
                finally {
                    this.isSaving = false;
                    this.fieldComment = '';

                    this.$emit('input', false);
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .drawer-bottom {
        border-top: 1px solid rgba(0, 0, 0, 0.12);

        .comment-field {
            ::v-deep .v-input__slot {
                box-shadow: none !important;
            }
        }
    }
</style>
