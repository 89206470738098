<template>
    <v-card>
        <v-card-title>
            <span>Entregas</span>

            <!-- <v-spacer></v-spacer> -->

            <!-- <span>Hoy</span> -->
        </v-card-title>

        <v-card-text class="white--text display-1 chart-container">
            <apexchart type="donut" :height="this.donutHeight" :options="options" :series="series"></apexchart>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        components: {},

        data() {
            return {
                options: {
                    chart: {
                        id: 'shipping-status-chart'
                    },
                    legend: {
                        show: false
                    },
                    dataLabels: {
                        enabled: false
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                size: '40%'
                            }
                        }
                    },
                    labels: [],
                    colors: [ '#000000' ]
                },
                colorsByState: {
                    'state.delivered': '#4CAF50',
                    'state.pending': '#2196F3',
                    'state.preparing': '#0D47A1',
                    'state.packed': '#009688',
                    'state.transit': '#ff9800',
                    'state.cancelled': '#F44336',
                    'state.returned': '#F44336'
                },
                series: []
            };
        },

        props: {
            donutHeight: {
                type: Number,
                default: () => 120
            },
            shipments: {
                type: Array
            }
        },

        computed: {
            seriesLabels() {
                if(this.ordersByState) {
                    return Object.keys(this.ordersByState).map((k, i) => {
                        return k;
                    });
                }
                else {
                    return [];
                }
            }
        },

        watch: {
            seriesLabels(val) {
                this.options.labels = val;
            },

            shipments(val) {
                let ordersByState = val.reduce((rv, x) => {
                    (rv[x.state] = rv[x.state] || []).push(x);
                    return rv;
                }, {});

                this.series = Object.keys(ordersByState).map((k, i) => {
                    return ordersByState[k].length;
                });

                // TODO: Updating labels with a reactive prop doesn't seem to be working, this is a manual workaround
                let labels = Object.keys(ordersByState).map((k, i) => k);
                let colors = labels.map((v) => this.colorsByState[v]);

                ApexCharts.exec('shipping-status-chart', 'updateOptions', {
                    labels: labels,
                    colors: colors
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
    .chart-container {
        margin-top: -60px;
        height: 100px;
    }
</style>
