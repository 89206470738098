<template>
    <template-layout>
        <template slot="drawers">
            <order-overview
                :active.sync="showOverviewDrawer"
                :order="selectedOrder"
            />

            <shipping-list-filters-drawer
                v-model="showFiltersDrawer"
                :defaultFilters="defaultFilters"
                :filters.sync="filters"
                :routes="routes"
            />
        </template>

        <template slot="content">
            <v-container class="py-6">
                <v-toolbar flat dense color="transparent" height="60" class="mb-2">
                    <v-toolbar-title>
                        <span class="headline">Envios del {{ filters.date | moment('dddd D [de] MMMM') }}</span>
                    </v-toolbar-title>

                    <v-toolbar-title>
                        <v-menu
                            ref="dateMenu"
                            v-model="dateMenu"
                            :close-on-content-click="true"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" icon><v-icon>mdi-calendar-edit</v-icon></v-btn>
                            </template>

                            <v-date-picker
                                v-model="filters.date"
                                no-title
                                :first-day-of-week="1"
                                locale="es-AR"
                                event-color="green lighten-1"
                            >
                            </v-date-picker>
                        </v-menu>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-toolbar-title>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon :loading="printSlipsLoading" :disabled="shippingItems.length <= 0" @click="onPrintPackingSlipsSelected" v-on="on"><v-icon>mdi-printer</v-icon></v-btn>
                            </template>
                            <span>Imprimir remitos</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon :loading="printInvoicesLoading" :disabled="shippingItems.length <= 0" @click="onPrintInvoicesSelected" v-on="on"><v-icon>mdi-cloud-download-outline</v-icon></v-btn>
                            </template>
                            <span>Imprimir facturas</span>
                        </v-tooltip>

                        <v-btn icon @click="showFiltersDrawer = !showFiltersDrawer"><v-icon>mdi-tune</v-icon></v-btn>

                    </v-toolbar-title>
                </v-toolbar>

                <v-row>
                    <v-col cols="6" sm="4">
                        <v-card style="height: 100%">
                            <v-card-text>
                                <h5 class="subtitle-1 font-weight-regular grey--text">Cantidad de envios</h5>
                                <h1 class="display-1 black--text">{{ totalShipments }}</h1>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="6" sm="4">
                        <v-card style="height: 100%">
                            <v-card-text>
                                <h5 class="subtitle-1 font-weight-regular grey--text">Cantidad de móviles</h5>
                                <h1 class="display-1 black--text">{{ vehicles.length }}</h1>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" sm="4">
                        <widget-shipping-status
                            :shipments="this.shippingItems"
                        />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-card>
                            <v-card-title>Envios</v-card-title>

                            <v-card-text>
                                <v-data-table
                                    class="elevation-0"
                                    hide-default-header
                                    hide-default-footer
                                    :headers="headers"
                                    :items="shippingItems"
                                    :items-per-page="-1"
                                    :loading="isLoading"
                                    no-data-text="No hay pedidos para mostrar"
                                    :disable-sort="true"
                                    @click:row="onRowSelected"
                                >
                                    <template v-slot:item.id="{ item }">
                                        {{ item.id }}
                                    </template>

                                    <template v-slot:item.orderId="{ item }">
                                        <router-link :to="{ name: 'orderView', params: { id: item.orderId } }">
                                            <v-icon class="mr-2 d-none d-sm-inline grey--text">mdi-cart-outline</v-icon>
                                            {{ item.orderId }}
                                        </router-link>
                                    </template>

                                    <template v-slot:item.customer="{ item }">
                                        {{ getCustomer(getOrder(item.orderId).customer).fullName | capitalize }}
                                    </template>

                                    <template v-slot:item.vehicle="{ item }">
                                        <v-icon class="mr-2 d-none d-sm-inline grey--text">mdi-truck-outline</v-icon>
                                        <template v-if="item && item.route && item.route.vehicle">
                                            {{ item.route.vehicle.name }}
                                        </template>
                                        <template v-else>
                                            Sin Móvil
                                        </template>
                                    </template>

                                    <template v-slot:item.state="{ item }">
                                        <tui-shipping-state-chip :state="item.state"></tui-shipping-state-chip>
                                    </template>

                                    <template v-slot:item.invoice="{ item }">
                                        <a class="text-right" v-if="getOrder(item.orderId).invoice" @click="onInvoiceSelected(getOrder(item.orderId).invoice)">{{ getOrder(item.orderId).invoice.invoiceFormattedNumber }}</a>
                                    </template>

                                    <template v-slot:item.icons="{item}">
                                        <v-icon class="mr-2 grey--text" v-if="getOrder(item.orderId).source === 'Web'">mdi-web</v-icon>
                                        <v-icon class="mr-2 grey--text" v-else>mdi-face-agent</v-icon>

                                        <v-icon class="mr-2 grey--text" v-if="getOrder(item.orderId).orderType === 'support'">mdi-lifebuoy</v-icon>

                                        <template v-if="getOrder(item.orderId).commentCount > 0">
                                            <v-icon class="grey--text">mdi-comment-text-outline</v-icon>
                                            <span class="mr-2 grey--text">{{ getOrder(item.orderId).commentCount }}</span>
                                        </template>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </template-layout>
</template>

<script>
    import TemplateLayout from '@/layout/TemplateLayout.vue';
    import TuiShippingStateChip from '@/ui/TUIShippingStateChip';
    import OrderOverview from '@/components/Order/OrderOverview';
    import ShippingListFiltersDrawer from './ShippingListFiltersDrawer';
    import WidgetShippingStatus from '@/components/Widgets/WidgetShippingStatus.vue';

    export default {
        components: {
            TemplateLayout,
            TuiShippingStateChip,
            OrderOverview,
            ShippingListFiltersDrawer,
            WidgetShippingStatus
        },

        data() {
            return {
                dateMenu: false,

                showOverviewDrawer: false,
                showFiltersDrawer: false,
                selectedOrder: null,

                printSlipsLoading: false,
                printInvoicesLoading: false,

                headers: [
                    { text: 'Envio #', value: 'id', sortable: false },
                    { text: 'Pedido #', value: 'orderId', sortable: false },
                    { text: 'Cliente', value: 'customer', sortable: false },
                    { text: 'Móvil', value: 'vehicle', sortable: false },
                    { text: 'Estado', value: 'state', sortable: false },
                    { text: 'Factura', value: 'invoice', sortable: false },
                    { text: '', value: 'icons', align: 'end' }
                ],

                filters: {},
                defaultFilters: {
                    date: this.$moment().format('YYYY-MM-DD')
                },

                shippingItems: []
            };
        },

        computed: {
            isLoading() {
                return this.$store.getters['deliveryRoutes/isLoading']() || this.$store.getters['shipments/isLoading']();
            },

            routes() {
                return this.$store.getters['deliveryRoutes/isDraft']() ? [] : this.$store.getters['deliveryRoutes/getAll']();
            },

            totalShipments() {
                return this.shippingItems.length;
            },

            vehicles() {
                return this.shippingItems.map(order => (order.route && order.route.vehicle) ? order.route.vehicle.id : null).filter((v, i, self) => {
                    return v !== null && self.indexOf(v) === i;
                });
            }
        },

        watch: {
            'filters.date': {
                handler(val) {
                    this.filters.route = -1;
                    this.fetchRoutes();
                }
            },

            filters: {
                handler(val) {
                    this.fetchOrders();
                },

                deep: true
            }
        },

        timers: {
            refreshTimer: {
                time: 30000,
                autostart: true,
                repeat: true
            }
        },

        async mounted() {
            this.filters = { ...this.defaultFilters };
        },

        methods: {
            refreshTimer() {
                this.fetchOrders();
            },

            getOrder(id) {
                return this.$store.getters['order/getById'](id);
            },

            getCustomer(id) {
                return this.$store.getters['customer/getById'](id);
            },

            async fetchRoutes() {
                await this.$store.dispatch('deliveryRoutes/fetchAll', {
                    date: this.filters.date
                });
            },

            async fetchOrders() {
                try {
                    let shipments = await this.$store.dispatch('shipments/fetchAll', {
                        date: this.filters.date,
                        state: 'state.pending,state.preparing,state.packed,state.transit,state.delivered,state.returned',
                        ...((this.filters.route && this.filters.route > -1) ? { route: this.filters.route } : {})
                    });

                    let orderIds = shipments.map(s => this.$store.getters['shipments/getById'](s)).map(s => s.orderId);

                    if(orderIds && orderIds.length > 0) {
                        await this.$store.dispatch('order/fetchMany', {
                            ids: orderIds
                        });
                    }

                    this.shippingItems = shipments.map(s => this.$store.getters['shipments/getById'](s));
                }
                finally {
                }
            },

            async onPrintPackingSlipsSelected() {
                this.printSlipsLoading = true;

                let data = await this.$api.getBlob('/api/shipments/packingSlips', {
                    date: this.filters.date,
                    state: 'state.pending,state.preparing,state.packed,state.transit,state.delivered,state.returned',
                    ...((this.filters.route && this.filters.route > -1) ? { route: this.filters.route } : {})
                });

                this.printSlipsLoading = false;

                var file = new Blob([data], { type: 'application/pdf' });
                var fileURL = window.URL.createObjectURL(file);
                window.open(fileURL);
            },

            async onPrintInvoicesSelected() {
                this.printInvoicesLoading = true;

                let orderIds = this.shippingItems.map(o => o.orderId);
                let invoiceIds = orderIds.map(id => this.$store.getters['order/getById'](id)).filter(o => o.invoice).map(o => o.invoice.id);

                if(!invoiceIds || invoiceIds.length <= 0) {
                    return;
                }

                let data = await this.$api.getBlob('/api/invoices/' + invoiceIds.join(','));

                this.printInvoicesLoading = false;

                var file = new Blob([data], { type: 'application/pdf' });
                var fileURL = window.URL.createObjectURL(file);
                window.open(fileURL);
            },

            async onInvoiceSelected(invoice) {
                let data = await this.$api.getBlob('/api/invoices/' + invoice.id);

                var file = new Blob([data], { type: 'application/pdf' });
                var fileURL = window.URL.createObjectURL(file);
                window.open(fileURL);
            },

            onRowSelected(shippingItem) {
                this.showOverviewDrawer = true;
                this.selectedOrder = this.getOrder(shippingItem.orderId);
            }
        }
    };
</script>

<style lang="scss" scoped>
</style>
