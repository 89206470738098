<template>
    <v-chip
        label
        x-small-chip
        :color="stateColor"
        text-color="white"
    >
        {{ stateName }}
    </v-chip>
</template>

<script>
    export default {
        name: 'tui-shipping-state-chip',

        props: {
            state: {
                type: String,
                default: ''
            },

            shrink: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            stateName() {
                let name = this.state;

                switch (this.state) {
                case 'state.pending':
                    name = 'Programado';
                    break;
                case 'state.preparing':
                    name = 'Preparando';
                    break;
                case 'state.packed':
                    name = 'Armado';
                    break;
                case 'state.transit':
                    name = 'Reparto';
                    break;
                case 'state.delivered':
                    name = 'Entregado';
                    break;
                case 'state.returned':
                    name = 'Fallido';
                    break;
                case 'state.cancelled':
                    name = 'Cancelado';
                    break;
                }

                if(this.shrink && this.$vuetify.breakpoint.smAndDown) {
                    name = name.charAt(0);
                }

                return name;
            },

            stateColor() {
                switch (this.state) {
                case 'state.delivered':
                    return 'green';
                case 'state.preparing':
                    return 'blue darken-4';
                case 'state.pending':
                    return 'blue';
                case 'state.packed':
                    return 'blue darken-4';
                case 'state.transit':
                    return 'orange';
                case 'state.returned':
                    return 'red';
                case 'state.cancelled':
                    return 'red';
                default:
                    return '';
                }
            }
        }
    };
</script>
