var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('template-layout',[_c('template',{slot:"drawers"},[_c('order-overview',{attrs:{"active":_vm.showOverviewDrawer,"order":_vm.selectedOrder},on:{"update:active":function($event){_vm.showOverviewDrawer=$event}}}),_c('shipping-list-filters-drawer',{attrs:{"defaultFilters":_vm.defaultFilters,"filters":_vm.filters,"routes":_vm.routes},on:{"update:filters":function($event){_vm.filters=$event}},model:{value:(_vm.showFiltersDrawer),callback:function ($$v) {_vm.showFiltersDrawer=$$v},expression:"showFiltersDrawer"}})],1),_c('template',{slot:"content"},[_c('v-container',{staticClass:"py-6"},[_c('v-toolbar',{staticClass:"mb-2",attrs:{"flat":"","dense":"","color":"transparent","height":"60"}},[_c('v-toolbar-title',[_c('span',{staticClass:"headline"},[_vm._v("Envios del "+_vm._s(_vm._f("moment")(_vm.filters.date,'dddd D [de] MMMM')))])]),_c('v-toolbar-title',[_c('v-menu',{ref:"dateMenu",attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-calendar-edit")])],1)]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","first-day-of-week":1,"locale":"es-AR","event-color":"green lighten-1"},model:{value:(_vm.filters.date),callback:function ($$v) {_vm.$set(_vm.filters, "date", $$v)},expression:"filters.date"}})],1)],1),_c('v-spacer'),_c('v-toolbar-title',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","loading":_vm.printSlipsLoading,"disabled":_vm.shippingItems.length <= 0},on:{"click":_vm.onPrintPackingSlipsSelected}},on),[_c('v-icon',[_vm._v("mdi-printer")])],1)]}}])},[_c('span',[_vm._v("Imprimir remitos")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","loading":_vm.printInvoicesLoading,"disabled":_vm.shippingItems.length <= 0},on:{"click":_vm.onPrintInvoicesSelected}},on),[_c('v-icon',[_vm._v("mdi-cloud-download-outline")])],1)]}}])},[_c('span',[_vm._v("Imprimir facturas")])]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showFiltersDrawer = !_vm.showFiltersDrawer}}},[_c('v-icon',[_vm._v("mdi-tune")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"4"}},[_c('v-card',{staticStyle:{"height":"100%"}},[_c('v-card-text',[_c('h5',{staticClass:"subtitle-1 font-weight-regular grey--text"},[_vm._v("Cantidad de envios")]),_c('h1',{staticClass:"display-1 black--text"},[_vm._v(_vm._s(_vm.totalShipments))])])],1)],1),_c('v-col',{attrs:{"cols":"6","sm":"4"}},[_c('v-card',{staticStyle:{"height":"100%"}},[_c('v-card-text',[_c('h5',{staticClass:"subtitle-1 font-weight-regular grey--text"},[_vm._v("Cantidad de móviles")]),_c('h1',{staticClass:"display-1 black--text"},[_vm._v(_vm._s(_vm.vehicles.length))])])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('widget-shipping-status',{attrs:{"shipments":this.shippingItems}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v("Envios")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-header":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.shippingItems,"items-per-page":-1,"loading":_vm.isLoading,"no-data-text":"No hay pedidos para mostrar","disable-sort":true},on:{"click:row":_vm.onRowSelected},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.orderId",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'orderView', params: { id: item.orderId } }}},[_c('v-icon',{staticClass:"mr-2 d-none d-sm-inline grey--text"},[_vm._v("mdi-cart-outline")]),_vm._v(" "+_vm._s(item.orderId)+" ")],1)]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.getCustomer(_vm.getOrder(item.orderId).customer).fullName))+" ")]}},{key:"item.vehicle",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2 d-none d-sm-inline grey--text"},[_vm._v("mdi-truck-outline")]),(item && item.route && item.route.vehicle)?[_vm._v(" "+_vm._s(item.route.vehicle.name)+" ")]:[_vm._v(" Sin Móvil ")]]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('tui-shipping-state-chip',{attrs:{"state":item.state}})]}},{key:"item.invoice",fn:function(ref){
var item = ref.item;
return [(_vm.getOrder(item.orderId).invoice)?_c('a',{staticClass:"text-right",on:{"click":function($event){_vm.onInvoiceSelected(_vm.getOrder(item.orderId).invoice)}}},[_vm._v(_vm._s(_vm.getOrder(item.orderId).invoice.invoiceFormattedNumber))]):_vm._e()]}},{key:"item.icons",fn:function(ref){
var item = ref.item;
return [(_vm.getOrder(item.orderId).source === 'Web')?_c('v-icon',{staticClass:"mr-2 grey--text"},[_vm._v("mdi-web")]):_c('v-icon',{staticClass:"mr-2 grey--text"},[_vm._v("mdi-face-agent")]),(_vm.getOrder(item.orderId).orderType === 'support')?_c('v-icon',{staticClass:"mr-2 grey--text"},[_vm._v("mdi-lifebuoy")]):_vm._e(),(_vm.getOrder(item.orderId).commentCount > 0)?[_c('v-icon',{staticClass:"grey--text"},[_vm._v("mdi-comment-text-outline")]),_c('span',{staticClass:"mr-2 grey--text"},[_vm._v(_vm._s(_vm.getOrder(item.orderId).commentCount))])]:_vm._e()]}}])})],1)],1)],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }