<template>
    <v-navigation-drawer
        v-model="active"
        right
        temporary
        fixed
        width="600"
    >
        <v-container class="pa-10">
            <v-row>
                <v-col cols="12">
                    <v-row no-gutters align="center">
                        <v-btn icon class="black--text" @click="onBackSelected">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <span class="headline">Filtros</span>
                    </v-row>
                </v-col>

                <v-col cols="12">
                    <v-select
                        label="Móvil"
                        v-model="newFilters.route"
                        :items="routes"
                        item-text="vehicle.name"
                        item-value="id"
                        required
                        clearable
                    >
                    </v-select>
                </v-col>
            </v-row>
        </v-container>

        <template v-slot:append>
            <v-row class="ma-4">
                <v-col cols="6">
                    <v-btn color="orange" dark block @click="onResetSelected">Resetear</v-btn>
                </v-col>

                <v-col cols="6">
                    <v-btn color="green" dark block @click="onApplySelected">Aplicar</v-btn>
                </v-col>
            </v-row>
        </template>
    </v-navigation-drawer>
</template>

<script>
    export default {
        components: {
        },

        props: {
            value: {
                type: Boolean,
                default: false
            },

            routes: {
                type: Array
            },

            defaultFilters: Object,
            filters: Object
        },

        data() {
            return {
                active: false,
                newFilters: {}
            };
        },

        computed: {
        },

        watch: {
            active(val) {
                this.$emit('input', val);
            },

            value(val) {
                this.active = val;
            },

            filters: {
                immediate: true,
                deep: true,
                handler(val) {
                    this.newFilters = { ...val };
                }
            }
        },

        methods: {
            onApplySelected() {
                this.$emit('update:filters', { ...this.newFilters });
                this.$emit('input', false);
            },

            onResetSelected() {
                let defaultFilters = JSON.parse(JSON.stringify(this.defaultFilters));
                this.$emit('update:filters', defaultFilters);
                this.$emit('input', false);
            },

            onBackSelected() {
                this.active = false;
            }
        }
    };
</script>

<style>

</style>
